import React from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../firebase';

const LoginButton = ({ firebase }) => (
    // <button type="button" onClick={firebase.doSignIn}>
    //     Login
    // </button>
    <Link className="nav-link" onClick={firebase.doSignIn} to='#'>Login</Link>
);

export default withFirebase(LoginButton);