import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import { Card } from 'react-bootstrap';

class AboutUsPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            content: [],
            error: false,
        };
    }

    render() {
        const { error, content, loading } = this.state;
        return (
            <div>
                <h1>About Us</h1>
                <p>ArtsFest, organized by students for students, is an annual celebration at the University of Chicago Laboratory High School that showcases our community’s creativity. Through workshops led by students, faculty, and guest artists, this event offers a chance to explore diverse art forms—both traditional and contemporary. ArtsFest is about more than creating—it’s about fostering a love and appreciation for the arts, inviting all to connect with creativity, whether novice or pro. A space to engage, learn, and inspire.</p>

                <p> <br /> </p> 

                <h3>Current Board:</h3>

                    <p>
                      Ilana Umanskiy - President (Workshops)<br />
                      Orly Eggener - President (Art in the Dark)<br />
                      Maya Pytel - Workshops<br />
                      Lydia Frost + Sebastian Gans - Advertising <br />
                      Millie Norton - Social Media <br />
                      Juliana Daugherty - Art In The Dark<br />
                      Elsa Podszus - The Hunt<br />
                      Krish Khanna - Technology<br />
                    </p>

                <p> <br /> </p> 
               


                <h3>ArtsFest - A Short Poem</h3>

                    <p>
                      A canvas stretched, a world to roam,<br />
                      Where whispers of art call hearts to home.<br />
                      In each brushstroke, a dream takes flight,<br />
                      Colors blooming in shadows of night.<br />
                    </p>

                    <p>
                      The rhythm beats, a pulse, a song—<br />
                      Unseen threads where we all belong.<br />
                      In whispered steps, a dance untold,<br />
                      Every soul is part of this story bold.<br />
                    </p>

                    <p>
                      Workshops ignite sparks of fire,<br />
                      Passion flows from hearts that never tire.<br />
                      Hands that shape, voices that soar—<br />
                      Talent - shared forevermore.<br />
                    </p>

                    <p>
                      A celebration of dreams unbound,<br />
                      Echoes resound of souls alight <br />
                      Together we rise, like stars that burn,<br />
                      In ArtsFest, we seek to grow, explore, and learn.<br />
                    </p>




            </div>
        );
    }
}

const WorkshopsList = ({ workshops }) => (
    <div>
        {workshops.map(workshop => (
            <Card style={{ width: '18rem' }}>
                <Card.Body>
                    <Card.Title>{workshop.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{workshop.location}</Card.Subtitle>
                    <Card.Text>
                        {workshop.placeholder}
                    </Card.Text>
                    <Card.Link href={`/view/${workshop.uid}`}>Read More</Card.Link>
                </Card.Body>
            </Card>
        ))}
    </div>
);

export default compose(withFirebase)(AboutUsPage);




