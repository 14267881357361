import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

const config = {
    apiKey: "AIzaSyCjYJc-bhGTIPptXFtBMxsSMK_ZXPCD0tk",
  authDomain: "ucls-artsfest.org",
  databaseURL: "https://ucls-artsfest-default-rtdb.firebaseio.com",
  projectId: "ucls-artsfest",
  storageBucket: "ucls-artsfest.appspot.com",
  messagingSenderId: "968016260918",
  appId: "1:968016260918:web:d71270f883c50fcc41cd97",
  measurementId: "G-HVXXZDE4BD"
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.db = app.database();
        this.googleProvider = new app.auth.GoogleAuthProvider();
    }

    valid_user = (uid) => this.db.ref(`valid_users/${uid}`);

    valid_users = () => this.db.ref(`valid_users`);

    user = (uid) => this.db.ref(`users/${uid}`);

    users = () => this.db.ref('users');

    signup_live = () => this.db.ref(`signup_live`);

    signup = (uid) => this.db.ref(`signups/${uid}`);

    signup2 = (uid, id) => this.db.ref(`signups/${uid}/${id}`);

    signups = () => this.db.ref(`signups`);

    doSignOut = () => this.auth.signOut();

    doSignIn = () => this.auth.signInWithPopup(this.googleProvider);

    workshops = () => this.db.ref(`workshops`);

    workshop = (uuid) => this.db.ref(`workshops/${uuid}`);

    onAuthUserListener = (next, fallback) => this.auth.onAuthStateChanged(authUser => {
        var authSuccess = true;
        var needFallback = false;
        console.log(authUser);
        if (authUser) {

            if (authUser.email.split('@').pop() !== "ucls.uchicago.edu" && authUser.email.split('@').pop() !== "gmail.com") {

                const error = "Please login with your Lab Google Account. (<your-labnet>@ucls.uchicago.edu)";
                alert(error);
                authSuccess = false;
                needFallback = true;
            } else {
                this.db.ref(`valid_users/${authUser.email.split('@')[0]}`).get().then(snapshot => {
                    if (!snapshot.exists()) {
                        console.log("hello jeff1");
                        const error = "You are not a current student of Lab. If you believe this is an error, please contact Krish Khanna (kkhanna@ucls.uchicago.edu).";
                        alert(error);
                        authSuccess = false;
                        needFallback = true;
                    } else {
                        if (snapshot.val().roles) {

                            this.db.ref(`users/${authUser.uid}`).set({
                                username: authUser.email.split('@')[0],
                                email: authUser.email,
                                first: snapshot.val().first,
                                last: snapshot.val().last,
                                grade: snapshot.val().grade,
                                roles: snapshot.val().roles,
                                photo: authUser.photoURL,
                            });
                        } else {
                            this.db.ref(`users/${authUser.uid}`).set({
                                username: authUser.email.split('@')[0],
                                email: authUser.email,
                                first: snapshot.val().first,
                                last: snapshot.val().last,
                                grade: snapshot.val().grade,
                                photo: authUser.photoURL,
                            });
                        }
                        this.user(authUser.uid).once('value').then(snapshot => {
                            const dbUser = snapshot.val();
                            if (!dbUser.roles) {
                                dbUser.roles = {};
                            }
                            authUser = {
                                uid: authUser.uid,
                                email: authUser.email,
                                ...dbUser,
                            };
            
                            next(authUser);
                        });
                    }
                });
            }
        } else {
            needFallback = true;
        }
        if (needFallback) {
            console.log("hello jeff2");
            fallback(authSuccess);
            this.auth.signOut();
        }
    });

}

export default Firebase;