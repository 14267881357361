import React from "react";

import LogoutButton from '../logout';
import LoginButton from '../login';

import { AuthUserContext } from "../session";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import * as ROLES from '../../constants/roles';

const Navigation = () => (
    <div className="navigation-bar">
        <AuthUserContext.Consumer>
            {authUser => authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />}
        </AuthUserContext.Consumer>
    </div>
);

const NavigationAuth = ({ authUser }) => (
    <Navbar expand="lg" variant="dark"  className="navigation-bar">
            <Navbar.Brand href="/">&nbsp;&nbsp;    U-High ArtsFest</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation" />
            <Navbar.Collapse id="navbarColor01">
                <Nav className="me-auto">
                    <Nav.Item>
                        <Nav.Link href="/aboutus">About Us</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/signup">Workshop Sign-Up</Nav.Link>
                    </Nav.Item>

                </Nav>
                <Nav className="ms-auto">
                {(!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.EXEC]) && (
                    <NavDropdown title="Admin" align="end">
                        {!!authUser.roles[ROLES.ADMIN] && (
                            <div><NavDropdown.Item href="/admin">Admin Panel</NavDropdown.Item>
                            <NavDropdown.Item href="/admin/viewrosters">View Rosters</NavDropdown.Item>
                            <NavDropdown.Item href="/admin/users">View Users</NavDropdown.Item>
                            </div>
                        )}
                    </NavDropdown>
                    )}
                    <Nav.Item><LogoutButton /></Nav.Item>
                    &nbsp;&nbsp;
                </Nav>
            </Navbar.Collapse>
    </Navbar>
);

/*<div className="container-fluid">
            <a className="navbar-brand" href="/">UCLS Student Council</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarColor01">
                <ul className="navbar-nav me-auto">
                    <li className="nav-item">
                        <NavLink exact="true" className="nav-link" to="/">Home</NavLink>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">About</a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" href="/about/constitution">Constitution</a>
                            <a className="dropdown-item" href="/about/members">Members</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <NavLink exact="true" className="nav-link" to="/grade_pages/9">9th</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact="true" className="nav-link" to="/grade_pages/10">10th</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact="true" className="nav-link" to="/grade_pages/11">11th</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact="true" className="nav-link" to="/grade_pages/12">12th</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact="true" className="nav-link" to="/account">Account</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact="true" className="nav-link" to="/admin">Admin</NavLink>
                    </li>
                    <li className="nav-item">
                        <LogoutButton />
                    </li>

                </ul>
            </div>
        </div>*/

// style={{color:"#FFFFFF"}}

const NavigationNonAuth = () => (
    <Navbar expand="lg" variant="dark" className='navigation-bar'>
            <Navbar.Brand href="/">&nbsp;&nbsp;    U-High ArtsFest</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation" />
            <Navbar.Collapse id="navbarColor01">
                <Nav className="me-auto">
                    <Nav.Item>
                        <Nav.Link href="/">Home</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/aboutus">About Us</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Nav className="ms-auto">
                    <Nav.Item>
                        <LoginButton className="login-button" />
                    </Nav.Item>
                    &nbsp;&nbsp;
                </Nav>
            </Navbar.Collapse>
    </Navbar>
);


/*
                    <Nav.Item>
                        <Nav.Link href="/myschedule">Your Schedule</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/viewrosters">View Rosters</Nav.Link>
                    </Nav.Item>
                    */

export default Navigation;