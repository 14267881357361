import React from "react";
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";

import Navigation from '../navigation';
import HomePage from '../home';
import AccountPage from "../account";
import AdminPage from "../admin";
import AboutUsPage from "../aboutus";
import Workshops from "../workshops";
import NewWorkshop from '../workshops/new';
import ViewWorkshop from '../workshops/view';
import SignUp from '../workshops/list';
import ViewSchedule from '../workshops/schedule';
import ViewRostersNonAdmin from '../viewRostersNonAdmin';
import ViewRoster from '../admin/viewroster';
import AdminViewSchedule from '../admin/viewschedule';
import ViewRosters from '../admin/rosters';
import ViewUsers from '../admin/users';
import NoSignUp from '../admin/nosign';


import NotFound from "../error_pages/404";
import Denied from "../error_pages/denied";

import { withAuthentication } from '../session';

const App = () => (
    <Router>
        <div>
            <Navigation />
            <div style={{margin: '2%',}}>
            <Routes>
                <Route path='' element={<HomePage />} />
                <Route path='account' element={<AccountPage />} />
                <Route path='admin' element={<AdminPage />} />
                <Route path='aboutus' element={<AboutUsPage />} />
                <Route path='403' element={<Denied />} />
                <Route path='signup' element={<SignUp />} />
                <Route path='new' element={<NewWorkshop />} />
                <Route path='myschedule' element={<ViewSchedule />} />
                <Route path='view/:id' element={<ViewWorkshop />} />
                <Route path='viewrosters' element={<ViewRostersNonAdmin />} />
                <Route path='admin/view/:id' element={<ViewRoster />} />
                <Route path='admin/viewrosters' element={<ViewRosters />} />
                <Route path='admin/users' element={<ViewUsers />} />
                <Route path='admin/nosign' element={<NoSignUp />} />
                <Route path='admin/viewschedule/:id' element={<AdminViewSchedule />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
            </div>
        </div>
    </Router>

);


export default withAuthentication(App);