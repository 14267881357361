import React, { useState, useEffect } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import { withFirebase } from '../firebase';

import { withAuthorization, AuthUserContext } from '../session';

import { compose } from 'recompose';

import { Table } from 'react-bootstrap';

import * as ROLES from '../../constants/roles';


function AdminViewSchedule(props) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [content, setContent] = useState([]);
    const [uid1, setUid1] = useState(null);
    const [uid2, setUid2] = useState(null);
    const [uid3, setUid3] = useState(null);
    const [uid4, setUid4] = useState(null);
    const [user, setUser] = useState([]);

    let { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        props.firebase.signup(id).on('value', snapshot => {
            const content = snapshot.val();
            if (snapshot.exists()) {
                if (content["1"] != null) setUid1(content["1"].uid);
                if (content["2"] != null) setUid2(content["2"].uid);
                if (content["3"] != null) setUid3(content["3"].uid);
                if (content["5"] != null) setUid4(content["5"].uid);
                if (content["4"] != null) {
                    setUid1(content["4"].uid);
                    setUid2(content["4"].uid);
                }
                if (content["6"] != null) {
                    setUid3(content["6"].uid);
                    setUid4(content["6"].uid);
                }
            }
        });

        props.firebase.user(id).on('value', snapshot => {
            setUser(snapshot.val());
        });
        props.firebase.workshops().get().then(snapshot => {
            if (!snapshot.exists()) {
                setError(true);
                setLoading(false);
            } else {
                props.firebase.workshops().on('value', snapshot => {
                    const content = snapshot.val();
                    
                    setContent(content);
                    setLoading(false);
                });
            }
        });
        // return a function to execute at unmount
        return () => {
            props.firebase.workshops().off();
            props.firebase.signup(id).off();
        }
      }, []);
    return (

        <div>
            {loading && <div>loading... </div>}
            {!loading && 
            <div>
                <h1>{user.first}&nbsp;{user.last}'s Schedule</h1>
                <br />
                <h5>To view the workshop signup list, click on the workshop title.</h5>
                <br />
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <Table striped bordered hover>
                    <thead>
                    <tr>
                    <th>Period</th>
                    <th>Time</th>
                    <th>Workshop Name</th>
                    <th>Location</th>
                    </tr>
                </thead>
                <tbody>
                        <tr>
                        <td>1</td>
                        <td>10:20 - 11:10</td>
                        <td>{uid1 != null ? <div><a href={`/admin/view/${uid1}`}>{content[uid1].title}</a></div>: <div>none</div>}</td>
                        <td>{uid1 != null ? <div>{content[uid1].location}</div>: <div>none</div>}</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>11:20 - 12:10</td>
                        <td>{uid2 != null ? <div><a href={`/admin/view/${uid2}`}>{content[uid2].title}</a></div>: <div>none</div>}</td>
                        <td>{uid2 != null ? <div>{content[uid2].location}</div>: <div>none</div>}</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>1:15 - 2:05</td>
                        <td>{uid3 != null ? <div><a href={`/admin/view/${uid3}`}>{content[uid3].title}</a></div>: <div>none</div>}</td>
                        <td>{uid3 != null ? <div>{content[uid3].location}</div>: <div>none</div>}</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>2:15 - 3:05</td>
                        <td>{uid4 != null ? <div><a href={`/admin/view/${uid4}`}>{content[uid4].title}</a></div>: <div>none</div>}</td>
                        <td>{uid4 != null ? <div>{content[uid4].location}</div>: <div>none</div>}</td>
                    </tr>
                    </tbody>
                    </Table>
                </div>}
            </div>}
        </div>

    );
}


const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];


export default compose(withFirebase, withAuthorization(condition), )(AdminViewSchedule)