import React from 'react';

import { Nav } from 'react-bootstrap';
import { withFirebase } from '../firebase';

const LogoutButton = ({ firebase }) => (
    // <button type="button" onClick={firebase.doSignOut}>
    //     Logout
    // </button>
    <Nav.Link onClick={firebase.doSignOut} href="/">Logout</Nav.Link>
);

export default withFirebase(LogoutButton);