import React from 'react';

const Denied = () => (
    <div className="card text-white bg-danger mb-3" style={{ width: '96%', margin: '2%' }}>
        <div className="card-header">Error</div>
        <div className="card-body">
            <h4 className="card-title">Access Denied</h4>
            <p className="card-text">Sign Ups have not yet opened up for Non-Workshop Leaders. Please wait until 9 PM. If you believe this is in error, please contact Krish Khanna (<a href="mailto:kkhanna@ucls.uchicago.edu">kkhanna@ucls.uchicago.edu</a>). If it is well past 8 PM, you may need to log out and close out the tab before logging back in, as a refresh will not reset the website adequately. </p>
        </div>
    </div>
);

export default Denied;