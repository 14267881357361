import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../firebase';

import { Card } from 'react-bootstrap';

class Workshops extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            content: [],
            error: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.workshops().get().then(snapshot => {
            if (!snapshot.exists()) {
                this.setState({ error: true, loading: false });
            } else {
                this.props.firebase.workshops().on('value', snapshot => {
                    const content = snapshot.val();
                    
                    const workshopsList = Object.keys(content).map(key => ({
                        ...content[key],
                        uuid: key,
                    })).reverse();
                    
                    this.setState({
                        content: workshopsList,
                        loading: false,
                    });
                });
            }
        });
    }

    componentWillUnmount() {
        this.props.firebase.workshops().off();
    }

    render() {
        const { error, content, loading } = this.state;
        return (
            <div>
                <h1>Workshops</h1>
                <br />
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <WorkshopsList workshops={content} />
                </div>}
            </div>

        );
    }
}

const WorkshopsList = ({ workshops }) => (
    <div>
        {workshops.map(workshop => (
            <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>{workshop.title}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{workshop.location}</Card.Subtitle>
              <Card.Text>
                {workshop.placeholder}
              </Card.Text>
              <Card.Link href={`/view/${workshop.uid}`}>Read More</Card.Link>
            </Card.Body>
          </Card>
        ))}
    </div>
);

export default compose(withFirebase, )(Workshops);