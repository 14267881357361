import React from 'react';

import { withAuthorization, AuthUserContext } from '../session';

const AccountPage = () => (
    <AuthUserContext.Consumer>
        {authUser => (
            <div>
                <h1>Account Page</h1>
                <h3>Welcome Back, {authUser.first}</h3>
                <br />
                <p>Grade: {authUser.grade} <br />
                First: {authUser.first} <br />
                Last: {authUser.last} <br />
                Email: {authUser.email} <br /></p>
            </div>
        )}
    </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);