import React, { useState, useEffect } from 'react';

import { withFirebase } from '../firebase';
import { Alert, Button, Form } from 'react-bootstrap';

import { compose } from 'recompose';
import { withAuthorization } from '../session';
import * as ROLES from '../../constants/roles';
import { v4 as uuid } from 'uuid';



function NewWorkshop(props) {
    const [location, setLocation] = useState("");
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [submit, setSubmit] = useState(false);
    const [length, setLength] = useState("");
    const [periods, setPeriods] = useState("");
    const [category, setCategory] = useState("");
    const [placeholder, setPlaceholder] = useState("");
    const [capacity, setCapacity] = useState("");

    useEffect(() => {
        // return a function to execute at unmount
        return () => {
        }
    }, []);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        const unique_id = uuid();
        const uid = unique_id.slice(0,8);
        props.firebase.workshop(uid).set({
            title: title,
            location: location,
            description: description,
            length: length,
            periods: periods,
            category: category,
            placeholder: placeholder,
            capacity: capacity,
            uid: uid,
        });
        setLocation("");
        setTitle("");
        setDescription("");
        setLength("");
        setPeriods("");
        setPlaceholder("");
        setCapacity("");
        setCategory("");
        setSubmit(true);
    }
    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Label htmlFor="title">
                    Title
                </Form.Label>
                <Form.Control placeholder="Workshop Name" type="text" id="title" value={title} onChange={e => { setTitle(e.target.value); setSubmit(false); }} />
                <br />
                <Form.Label htmlFor="location">
                    Location
                </Form.Label>
                <Form.Control placeholder="N320" type="text" id="location" value={location} onChange={e => { setLocation(e.target.value); setSubmit(false); }} />
                <br />
                <Form.Label htmlFor="length">
                    Length
                </Form.Label>
                <Form.Control placeholder="1" type="text" id="length" value={length} onChange={e => { setLength(e.target.value); setSubmit(false); }} />
                <br />
                <Form.Label htmlFor="periods">
                    Periods
                </Form.Label>
                <Form.Control placeholder="1" type="text" id="periods" value={periods} onChange={e => { setPeriods(e.target.value); setSubmit(false); }} />
                <br />
                <Form.Label htmlFor="capacity">
                    Capacity
                </Form.Label>
                <Form.Control placeholder="12" type="text" id="capacity" value={capacity} onChange={e => { setCapacity(e.target.value); setSubmit(false); }} />
                <br />
                <Form.Label htmlFor="category">
                    Category
                </Form.Label>
                <Form.Control placeholder="1,2,3,4,5,6" type="text" id="category" value={category} onChange={e => { setCategory(e.target.value); setSubmit(false); }} />
                <br />
                <Form.Label htmlFor="placeholder">
                    Placeholder
                </Form.Label>
                <Form.Control placeholder="shortened description" type="text" id="placeholder" value={placeholder} onChange={e => { setPlaceholder(e.target.value); setSubmit(false); }} />
                <br />
                <Form.Label htmlFor="description">
                    Description
                </Form.Label>
                <Form.Control as="textarea" rows={6} id="description" value={description} onChange={e => { setDescription(e.target.value); setSubmit(false); }} />
                <br />
                <Button disabled={location.length == 0 || description.length == 0 || title.length == 0 || length.length == 0 || periods.length == 0 || category.length==0 || placeholder.length == 0 || capacity.length == 0} type="submit">Create New Workshop</Button>
            </Form>
            <br />
            <Alert show={submit} variant="success">Your workshop has been added.</Alert>
        </div>

    );
}

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withFirebase, withAuthorization(condition),)(NewWorkshop);

